import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { numberFormatDeleteDots } from '../../../utils/helper';
import { logicFormatSim } from './logicFormatSim';
import { LogicNetWork } from '../../Home/LogicSearch/LogicNetWork';
import { Link } from 'react-router-dom';

async function readExcelFile(file) {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);

  const worksheet = workbook.worksheets[0];
  const rows = [];

  worksheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
    // row.eachCell({ includeEmpty: true }, (cell, cellIndex) => {
    //   const phone = `${numberFormatDeleteDots(cell.value)}`;
    //   console.log('Cell ' + rowIndex + ' = ' + cell.value);
    //   // const checkFormatSim = logicFormatSim(phone);
    //   // const textSim = `${phone} - ${checkFormatSim}`;

    //   rows.push(cell.value);
    // });
    const rowData = row.values;
    const sim = `${numberFormatDeleteDots(rowData[1])}`;
    const sim_show = rowData[2] ? rowData[2] : '';
    const price = rowData[3] ? rowData[3] : '';
    const mang = rowData[4] ? rowData[4] : '';
    const thuebao = rowData[5] ? rowData[5] : '';
    const objsim = [sim, sim_show, price, mang, thuebao];
    rows.push(objsim);
  });

  return rows;
}

function Excel() {
  const [dataExcel, setDataExcel] = useState([]);
  const [numberDefault, setNumberDefault] = useState([]);
  const [numberDefault6So, setNumberDefault6So] = useState([]);
  const [dataXoaSo, setDataXoaSo] = useState([]);
  const [dataLocSo, setDataLocSo] = useState([]);
  const [dataLocSoOld, setDataLocSoOld] = useState([]);

  function mixPairs() {
    const arr = [
      '13',
      '31',
      '14',
      '41',
      '19',
      '91',
      '26',
      '62',
      '27',
      '72',
      '28',
      '82',
      '34',
      '43',
      '39',
      '93',
      '49',
      '94',
      '67',
      '76',
      '68',
      '86',
      '78',
      '87',
    ];
    let usedPairs = new Set();
    let pairList = [];
    let allPossiblePairs = [];

    // Tạo tất cả các cặp có thể, bao gồm cả chính nó
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        let pair = arr[i] + arr[j];
        allPossiblePairs.push(pair);
      }
    }

    // Xáo trộn danh sách cặp để random
    // allPossiblePairs = allPossiblePairs.sort(() => Math.random() - 0.5);

    // Lặp qua danh sách đã xáo trộn và thêm vào kết quả nếu chưa có
    for (let pair of allPossiblePairs) {
      if (!usedPairs.has(pair)) {
        usedPairs.add(pair);
        pairList.push(pair);
      }
    }
    // console.log(pairList);
    setNumberDefault(pairList);
  }

  function mixPairs6() {
    const arr = [
      '13',
      '31',
      '14',
      '41',
      '19',
      '91',
      '26',
      '62',
      '27',
      '72',
      '28',
      '82',
      '34',
      '43',
      '39',
      '93',
      '49',
      '94',
      '67',
      '76',
      '68',
      '86',
      '78',
      '87',
    ];
    let usedPairs = new Set();
    let pairList = [];
    let allPossiblePairs = [];

    // Tạo tất cả các cặp có thể, bao gồm cả chính nó
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        for (let k = 0; k < arr.length; k++) {
          let pair = arr[i] + arr[j] + arr[k];
          allPossiblePairs.push(pair);
        }
      }
    }

    // Xáo trộn danh sách cặp để random
    // allPossiblePairs = allPossiblePairs.sort(() => Math.random() - 0.5);

    // Lặp qua danh sách đã xáo trộn và thêm vào kết quả nếu chưa có
    for (let pair of allPossiblePairs) {
      if (!usedPairs.has(pair)) {
        usedPairs.add(pair);
        pairList.push(pair);
      }
    }
    setNumberDefault6So(pairList);
  }

  useEffect(() => {
    mixPairs();
    mixPairs6();
  }, []);

  function handleFileChange(e) {
    const file = e.target.files[0];

    readExcelFile(file)
      .then((data) => {
        let filteredData = data.slice();
        // filteredData = filteredData.map((num) => {
        //   return ['0' + num[0], num[1], num[2], num[3] ? num[3] : LogicNetWork('0' + num[0]), 'Trả Trước'];
        // });

        // Bước 3: Lọc các số có 4 số cuối nằm trong data2
        filteredData = filteredData.map((num) => {
          let lastFourDigits = num[0].slice(-4);
          let isMatch = numberDefault.some((suffix) => lastFourDigits === suffix);

          return {
            status: isMatch ? '1' : '',
            sim: '0' + num[0],
            sim_show: num[1],
            price: parseInt(num[2]),
            mang: num[3] ? num[3] : LogicNetWork('0' + num[0]),
            thuebao: 'Trả Trước',
          };
        });

        filteredData = filteredData.map((num) => {
          let originalNumber = num.sim;

          // Bước 1: Loại bỏ tất cả số 5
          let noFive = originalNumber.replace(/5/g, '');

          // Bước 2: Loại bỏ các ký tự trùng lặp liên tiếp (ví dụ: 444 → 4)
          let compacted = '';
          for (let i = 0; i < noFive.length; i++) {
            if (i === 0 || noFive[i] !== noFive[i - 1]) {
              compacted += noFive[i];
            }
          }

          // Bước 3: Lấy 4 số cuối
          let lastFourDigits = compacted.slice(-4);

          // Bước 4: Kiểm tra trùng với numberDefault
          let isMatch = numberDefault.includes(lastFourDigits);
          return {
            status: isMatch ? '1' : num.status,
            sim: num.sim,
            sim_show: num.sim_show,
            price: num.price,
            mang: num.mang,
            thuebao: num.thuebao,
          };
        });

        ////////////////////////////////////////////
        // Bước 3: Lọc các số có 6 số cuối nằm trong data2
        filteredData = filteredData.map((num) => {
          let lastFourDigits = num.sim.slice(-6);
          let isMatch = numberDefault6So.some((suffix) => lastFourDigits === suffix);

          return {
            status: isMatch ? '2' : num.status,
            sim: '0' + num.sim,
            sim_show: num.sim_show,
            price: parseInt(num.price),
            mang: num.mang ? num.mang : LogicNetWork('0' + num.sim),
            thuebao: 'Trả Trước',
          };
        });

        filteredData = filteredData.map((num) => {
          let originalNumber = num.sim;

          // Bước 1: Loại bỏ tất cả số 5
          let noFive = originalNumber.replace(/5/g, '');

          // Bước 2: Loại bỏ các ký tự trùng lặp liên tiếp (ví dụ: 444 → 4)
          let compacted = '';
          for (let i = 0; i < noFive.length; i++) {
            if (i === 0 || noFive[i] !== noFive[i - 1]) {
              compacted += noFive[i];
            }
          }

          // Bước 3: Lấy 4 số cuối
          let lastFourDigits = compacted.slice(-6);

          // Bước 4: Kiểm tra trùng với numberDefault
          let isMatch = numberDefault6So.includes(lastFourDigits);
          return {
            status: isMatch ? '2' : num.status,
            sim: num.sim,
            sim_show: num.sim_show,
            price: num.price,
            mang: num.mang,
            thuebao: num.thuebao,
          };
        });

        // filteredData = filteredData.map((num) => {
        //   return {
        //     status: num.status,
        //     sim: num.sim,
        //     sim_show: num.sim_show,
        //     price: num.status === '2' ? num.price + 500000 : num.status === '1' ? num.price + 300000 : num.price,
        //     price,
        //     mang: num.mang,
        //     thuebao: num.thuebao,
        //   };
        // });

        // Bước 5: Loại bỏ các chuỗi trùng lặp
        filteredData = [...new Set(filteredData)];

        // Bước 6: Xóa các số trong data3 khỏi danh sách
        if (dataXoaSo.length > 0) {
          filteredData = filteredData.filter((num) => !dataXoaSo.includes(num.sim));
        }
        setDataExcel(filteredData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleFileChangeTSH(e) {
    const file = e.target.files[0];

    readExcelFile(file)
      .then((data) => {
        // Bước 1: Loại bỏ các số chứa "0" trong chuỗi
        let filteredData = data.filter((num) => {
          return !num[0].includes('0');
        });

        // Bước 3: Lọc các số có 4 số cuối nằm trong data2
        filteredData = filteredData.map((num) => {
          let lastFourDigits = num[0].slice(-4);
          let isMatch = numberDefault.some((suffix) => lastFourDigits === suffix);

          return {
            status: isMatch ? '1' : '',
            sim: '0' + num[0],
            sim_show: num[1],
            price: num[2],
            mang: num[3] ? num[3] : LogicNetWork('0' + num[0]),
            thuebao: 'Trả Trước',
          };
        });

        filteredData = filteredData.map((num) => {
          let originalNumber = num.sim;

          // Bước 1: Loại bỏ tất cả số 5
          let noFive = originalNumber.replace(/5/g, '');

          // Bước 2: Loại bỏ các ký tự trùng lặp liên tiếp (ví dụ: 444 → 4)
          let compacted = '';
          for (let i = 0; i < noFive.length; i++) {
            if (i === 0 || noFive[i] !== noFive[i - 1]) {
              compacted += noFive[i];
            }
          }

          // Bước 3: Lấy 4 số cuối
          let lastFourDigits = compacted.slice(-4);

          // Bước 4: Kiểm tra trùng với numberDefault
          let isMatch = numberDefault.includes(lastFourDigits);
          return {
            status: isMatch ? '1' : num.status,
            sim: num.sim,
            sim_show: num.sim_show,
            price: num.price,
            mang: num.mang,
            thuebao: num.thuebao,
          };
        });

        filteredData = filteredData.filter((item) => item.status === '1');
        // Bước 5: Loại bỏ các chuỗi trùng lặp
        filteredData = [...new Set(filteredData)];
        // Bước 6: Xóa các số trong data3 khỏi danh sách
        if (dataXoaSo.length > 0) {
          filteredData = filteredData.filter((num) => !dataXoaSo.includes(num.sim));
        }

        setDataExcel(filteredData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleFileChangeTSH6SO(e) {
    const file = e.target.files[0];

    readExcelFile(file)
      .then((data) => {
        // Bước 1: Loại bỏ các số chứa "0" trong chuỗi
        let filteredData = data.filter((num) => {
          return !num[0].includes('0');
        });

        // Bước 3: Lọc các số có 4 số cuối nằm trong data2
        filteredData = filteredData.map((num) => {
          let lastFourDigits = num[0].slice(-6);
          let isMatch = numberDefault6So.some((suffix) => lastFourDigits === suffix);

          return {
            status: isMatch ? '1' : '',
            sim: '0' + num[0],
            sim_show: num[1],
            price: num[2],
            mang: num[3] ? num[3] : LogicNetWork('0' + num[0]),
            thuebao: 'Trả Trước',
          };
        });

        filteredData = filteredData.map((num) => {
          let originalNumber = num.sim;

          // Bước 1: Loại bỏ tất cả số 5
          let noFive = originalNumber.replace(/5/g, '');

          // Bước 2: Loại bỏ các ký tự trùng lặp liên tiếp (ví dụ: 444 → 4)
          let compacted = '';
          for (let i = 0; i < noFive.length; i++) {
            if (i === 0 || noFive[i] !== noFive[i - 1]) {
              compacted += noFive[i];
            }
          }

          // Bước 3: Lấy 4 số cuối
          let lastFourDigits = compacted.slice(-6);

          // Bước 4: Kiểm tra trùng với numberDefault
          let isMatch = numberDefault6So.includes(lastFourDigits);
          return {
            status: isMatch ? '1' : num.status,
            sim: num.sim,
            sim_show: num.sim_show,
            price: num.price,
            mang: num.mang,
            thuebao: num.thuebao,
          };
        });

        filteredData = filteredData.filter((item) => item.status === '1');
        // Bước 5: Loại bỏ các chuỗi trùng lặp
        filteredData = [...new Set(filteredData)];
        // Bước 6: Xóa các số trong data3 khỏi danh sách
        if (dataXoaSo.length > 0) {
          filteredData = filteredData.filter((num) => !dataXoaSo.includes(num.sim));
        }

        setDataExcel(filteredData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleFileChangeNOTSH(e) {
    const file = e.target.files[0];

    readExcelFile(file)
      .then((data) => {
        // Bước 1: Loại bỏ các số chứa "0" trong chuỗi
        let filteredData = data.filter((num) => {
          return !num[0].includes('0');
        });

        // Bước 3: Lọc các số có 4 số cuối nằm trong data2
        filteredData = filteredData.map((num) => {
          let lastFourDigits = num[0].slice(-4);
          let isMatch = numberDefault.some((suffix) => lastFourDigits === suffix);

          return {
            status: isMatch ? '' : '1',
            sim: '0' + num[0],
            sim_show: num[1],
            price: num[2],
            mang: num[3] ? num[3] : LogicNetWork('0' + num[0]),
            thuebao: 'Trả Trước',
          };
        });

        filteredData = filteredData.map((num) => {
          let originalNumber = num.sim;

          // Bước 1: Loại bỏ tất cả số 5
          let noFive = originalNumber.replace(/5/g, '');

          // Bước 2: Loại bỏ các ký tự trùng lặp liên tiếp (ví dụ: 444 → 4)
          let compacted = '';
          for (let i = 0; i < noFive.length; i++) {
            if (i === 0 || noFive[i] !== noFive[i - 1]) {
              compacted += noFive[i];
            }
          }

          // Bước 3: Lấy 4 số cuối
          let lastFourDigits = compacted.slice(-4);

          // Bước 4: Kiểm tra trùng với numberDefault
          let isMatch = numberDefault.includes(lastFourDigits);
          return {
            status: isMatch ? num.status : '1',
            sim: num.sim,
            sim_show: num.sim_show,
            price: num.price,
            mang: num.mang,
            thuebao: num.thuebao,
          };
        });

        filteredData = filteredData.filter((item) => item.status === '1');
        // Bước 5: Loại bỏ các chuỗi trùng lặp
        filteredData = [...new Set(filteredData)];
        // Bước 6: Xóa các số trong data3 khỏi danh sách
        if (dataXoaSo.length > 0) {
          filteredData = filteredData.filter((num) => !dataXoaSo.includes(num.sim));
        }
        console.log(1122, filteredData);

        setDataExcel(filteredData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const exportToExcelBCLS = (data) => {
    // Tạo một danh sách các đối tượng con tương ứng với từng dòng trong mảng data
    const worksheetData = data.map((item) => {
      // console.log(data);
      // console.log(item);
      // const [phone, names] = item.split(' - ');
      // const [phone] = item;
      // const [dinhdang6, dinhdang4, dinhdang3, dinhdang8] = names.split(',');

      return { sim: item.sim, 'sim tach': item.sim_show, gia: item.price, mang: item.mang, 'thue bao': item.thuebao };
    });

    // Tạo workbook và worksheet mới từ dữ liệu được chuyển đổi, sử dụng thư viện xlsx
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Chuyển đổi workbook thành dạng binary tương ứng với file Excel
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Tạo blob từ dữ liệu excel để tải xuống
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Sử dụng hàm saveAs từ thư viện file-saver để tải xuống tệp
    saveAs(blob, 'data.xlsx');
  };

  // Gọi hàm exportToExcel khi bạn muốn tải xuống

  const handleExportBCLS = () => {
    exportToExcelBCLS(dataExcel);
  };

  const convertTextToArray = (data) => {
    if (data) {
      const dataArray = data.split('\n');
      setDataXoaSo(dataArray);
    } else {
      setDataXoaSo([]);
    }
  };

  const convertTextToArrayLocSo = (data) => {
    if (data) {
      const dataArrayOld = data.split('\n');
      setDataLocSoOld(dataArrayOld);
      // console.log(phones, dataArrayOld);
      setDataLocSo(data);
    } else {
      setDataLocSo([]);
    }
  };

  function subLoc4So() {
    // Bước 1: Loại bỏ các số chứa "0" trong chuỗi
    const arr = ['72', '27', '28', '82', '26', '62', '66', '77'];
    const phones = [];
    const dataArray = dataLocSo.split('\n');

    dataArray.forEach((item) => {
      // Tìm cụm có thể là số điện thoại (9–13 ký tự có thể kèm dấu chấm, dấu phẩy)
      const matches = item.match(/(?:\d[.,]?){9,13}/g);

      if (matches) {
        matches.forEach((match) => {
          const digitsOnly = match.replace(/[^\d]/g, ''); // loại bỏ tất cả ký tự không phải số

          if (digitsOnly.length === 9) {
            phones.push('0' + digitsOnly); // thêm 0 nếu thiếu
          } else if (digitsOnly.length === 10) {
            phones.push(digitsOnly); // giữ nguyên nếu đủ
          }
          // Bỏ nếu <9 hoặc >10 số
        });
      }
    });
    let filteredData = phones.filter((num) => {
      return num.startsWith('0') && num.slice(1).indexOf('0') === -1;
    });

    // Bước 3: Lọc các số có 4 số cuối nằm trong data2
    filteredData = filteredData.map((num) => {
      let lastFourDigits = num.slice(-4);
      let isMatch = numberDefault.some((suffix) => lastFourDigits === suffix);

      return {
        status: isMatch ? '1' : '',
        sim: num,
      };
    });

    filteredData = filteredData.map((num) => {
      let originalNumber = num.sim;

      // Bước 1: Loại bỏ tất cả số 5
      // let noFive = originalNumber.replace(/5/g, '');

      // Bước 2: Loại bỏ các ký tự trùng lặp liên tiếp (ví dụ: 444 → 4)
      let compacted = '';
      for (let i = 0; i < originalNumber.length; i++) {
        if (i === 0 || originalNumber[i] !== originalNumber[i - 1]) {
          compacted += originalNumber[i];
        }
      }

      // Bước 3: Lấy 4 số cuối
      let lastFourDigits = compacted.slice(-4);

      // Bước 4: Kiểm tra trùng với numberDefault
      let isMatch = numberDefault.includes(lastFourDigits);
      return {
        status: isMatch ? '1' : num.status,
        sim: num.sim,
      };
    });

    // Bước 3: Lọc các số có 4 số cuối nằm trong data2
    filteredData = filteredData.map((num) => {
      let lastFourDigits = num.sim.slice(-6);
      let isMatch = numberDefault6So.some((suffix) => lastFourDigits === suffix);

      return {
        status: isMatch ? '2' : num.status,
        sim: num.sim,
      };
    });

    filteredData = filteredData.map((num) => {
      let originalNumber = num.sim;

      // Bước 1: Loại bỏ tất cả số 5
      // let noFive = originalNumber.replace(/5/g, '');

      // Bước 2: Loại bỏ các ký tự trùng lặp liên tiếp (ví dụ: 444 → 4)
      let compacted = '';
      for (let i = 0; i < originalNumber.length; i++) {
        if (i === 0 || originalNumber[i] !== originalNumber[i - 1]) {
          compacted += originalNumber[i];
        }
      }

      // Bước 3: Lấy 4 số cuối
      let lastFourDigits = compacted.slice(-6);

      // Bước 4: Kiểm tra trùng với numberDefault
      let isMatch = numberDefault6So.includes(lastFourDigits);
      return {
        status: isMatch ? '2' : num.status,
        sim: num.sim,
      };
    });

    filteredData = filteredData.map((num) => {
      let originalNumber = num.sim;

      // Bước 3: Lấy 4 số cuối
      let lastFourDigits = originalNumber.slice(-2);

      // Bước 4: Kiểm tra trùng với numberDefault
      let isMatch = arr.includes(lastFourDigits);
      return {
        status: isMatch ? '' : num.status,
        sim: num.sim,
      };
    });

    filteredData = filteredData.filter((item) => item.status === '1' || item.status === '2');
    console.log(filteredData);
    // Bước 5: Loại bỏ các chuỗi trùng lặp
    filteredData = [...new Set(filteredData)];
    // Bước 6: Xóa các số trong data3 khỏi danh sách
    if (dataXoaSo.length > 0) {
      filteredData = filteredData.filter((num) => !dataXoaSo.includes(num.sim));
    }

    // lấy dữ liệu hoàn thiện
    const simList = filteredData.map((item) => item.sim);

    const datanew = dataLocSoOld.reduce((acc, entry) => {
      const match = entry.match(/(?:\d[.,]?){9,13}/g);

      if (match && match.length > 0) {
        const cleaned = match[0].replace(/[^\d]/g, '');

        if (simList.includes(cleaned)) {
          const matched = filteredData.find((item) => item.sim === cleaned);

          acc.push({
            sim: entry,
            status: matched ? matched.status : '0',
          });
        }
      }

      return acc;
    }, []);
    // console.log(datanew);
    setDataExcel(datanew);
  }

  function subLoc6So() {
    // Bước 1: Loại bỏ các số chứa "0" trong chuỗi
    const arr = ['72', '27', '28', '82', '26', '62', '66', '77'];
    const phones = [];
    const dataArray = dataLocSo.split('\n');

    dataArray.forEach((item) => {
      // Tìm cụm có thể là số điện thoại (9–13 ký tự có thể kèm dấu chấm, dấu phẩy)
      const matches = item.match(/(?:\d[.,]?){9,13}/g);

      if (matches) {
        matches.forEach((match) => {
          const digitsOnly = match.replace(/[^\d]/g, ''); // loại bỏ tất cả ký tự không phải số

          if (digitsOnly.length === 9) {
            phones.push('0' + digitsOnly); // thêm 0 nếu thiếu
          } else if (digitsOnly.length === 10) {
            phones.push(digitsOnly); // giữ nguyên nếu đủ
          }
          // Bỏ nếu <9 hoặc >10 số
        });
      }
    });
    let filteredData = phones.filter((num) => {
      return num.startsWith('0') && num.slice(1).indexOf('0') === -1;
    });

    // Bước 3: Lọc các số có 4 số cuối nằm trong data2
    filteredData = filteredData.map((num) => {
      let lastFourDigits = num.slice(-6);
      let isMatch = numberDefault6So.some((suffix) => lastFourDigits === suffix);

      return {
        status: isMatch ? '1' : '',
        sim: num,
      };
    });

    filteredData = filteredData.map((num) => {
      let originalNumber = num.sim;

      // Bước 1: Loại bỏ tất cả số 5
      let noFive = originalNumber.replace(/5/g, '');

      // Bước 2: Loại bỏ các ký tự trùng lặp liên tiếp (ví dụ: 444 → 4)
      let compacted = '';
      for (let i = 0; i < originalNumber.length; i++) {
        if (i === 0 || originalNumber[i] !== originalNumber[i - 1]) {
          compacted += originalNumber[i];
        }
      }

      // Bước 3: Lấy 4 số cuối
      let lastFourDigits = compacted.slice(-6);

      // Bước 4: Kiểm tra trùng với numberDefault
      let isMatch = numberDefault6So.includes(lastFourDigits);
      return {
        status: isMatch ? '1' : num.status,
        sim: num.sim,
      };
    });

    filteredData = filteredData.map((num) => {
      let originalNumber = num.sim;

      // Bước 3: Lấy 4 số cuối
      let lastFourDigits = originalNumber.slice(-2);

      // Bước 4: Kiểm tra trùng với numberDefault
      let isMatch = arr.includes(lastFourDigits);
      return {
        status: isMatch ? '' : num.status,
        sim: num.sim,
      };
    });

    filteredData = filteredData.filter((item) => item.status === '1');
    // Bước 5: Loại bỏ các chuỗi trùng lặp
    filteredData = [...new Set(filteredData)];
    // Bước 6: Xóa các số trong data3 khỏi danh sách
    if (dataXoaSo.length > 0) {
      filteredData = filteredData.filter((num) => !dataXoaSo.includes(num.sim));
    }
    // lấy dữ liệu hoàn thiện
    const simList = filteredData.map((item) => item.sim);

    const datanew = dataLocSoOld.reduce((acc, entry) => {
      const match = entry.match(/(?:\d[.,]?){9,13}/g);

      if (match && match.length > 0) {
        const cleaned = match[0].replace(/[^\d]/g, '');

        if (simList.includes(cleaned)) {
          const matched = filteredData.find((item) => item.sim === cleaned);

          acc.push({
            sim: entry,
            status: matched ? matched.status : '0',
          });
        }
      }

      return acc;
    }, []);

    console.log(datanew);
    setDataExcel(datanew);
  }

  return (
    <Container sx={{ pb: '65px' }}>
      <Button variant="contained" color="success" component={Link} to="/dashboard">
        Quay lại
      </Button>
      <Grid container>
        <Grid item md={3} xs={12} sx={{ m: 1 }}>
          <Button onClick={handleExportBCLS} variant="contained" sx={{ mb: 2 }}>
            Tải xuống Excel
          </Button>
          <label>LỌC FILE TRÙNG VÀ SỐ CẦN XOÁ</label>
          <TextField label="" size="small" type="file" onChange={handleFileChange} focused />
          <br />
          <br />

          <label>LỌC FILE TRÙNG VÀ SỐ CẦN XOÁ VÀ LỌC ĐUÔI SỐ </label>
          <TextField label="" size="small" type="file" onChange={handleFileChangeTSH} focused />
          <br />
          <br />

          <label>LỌC FILE TRÙNG VÀ SỐ CẦN XOÁ VÀ LỌC ĐUÔI 6 SỐ </label>
          <TextField label="" size="small" type="file" onChange={handleFileChangeTSH6SO} focused />
          <br />
          <br />

          <label>LỌC FILE SỐ KHÔNG THUỘC DÃY CẦN BÁN </label>
          <TextField label="" size="small" type="file" onChange={handleFileChangeNOTSH} focused />
          <br />
          <br />
          <Box sx={{ mt: 1.5 }}>
            <TextField
              label="SỐ CẦN XOÁ"
              size="small"
              variant="outlined"
              value={dataXoaSo}
              color="error"
              multiline
              rows={4}
              onChange={(e) => {
                convertTextToArray(e.target.value);
              }}
              fullWidth
              focused
            />
          </Box>
          <Box sx={{ mt: 1.5 }}>
            <TextField
              label="TEXT Lọc số"
              size="small"
              variant="outlined"
              value={dataLocSo}
              color="error"
              multiline
              rows={4}
              onChange={(e) => {
                convertTextToArrayLocSo(e.target.value);
              }}
              fullWidth
              focused
            />
          </Box>
          <Button variant="contained" onClick={subLoc4So}>
            LỌC 4 SỐ
          </Button>
          <Button variant="contained" onClick={subLoc6So}>
            LỌC 6 SỐ
          </Button>
        </Grid>
        <Grid item md={12} xs={12} sx={{ m: 1 }}>
          {dataExcel.map((row, i) => (
            <div key={i} style={{ color: row.status === '2' ? 'red' : 'black', fontSize: '15px' }}>
              {row[0] || row.sim}
            </div>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Excel;
